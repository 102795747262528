"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.retrieveCurrentLanguage = exports.lazyLoadImages = exports.isIE = exports.loadScript = exports.loadStyle = exports.checkJS = exports.getResourceUrl = void 0;
function getResourceUrl(chunk) {
    var separator = chunk.lastIndexOf('.');
    var basename = chunk.substring(0, separator);
    var extension = chunk.substring(separator);
    return __webpack_public_path__ + basename + (__resource_suffix__ || '') + extension;
}
exports.getResourceUrl = getResourceUrl;
function checkJS() {
    document.documentElement.className = document.documentElement.className.replace(/\bno-js\b/, 'js');
}
exports.checkJS = checkJS;
function loadStyle(href, media, id) {
    if (media === void 0) { media = 'all'; }
    if (id && document.querySelector("#".concat(id)))
        return;
    var style = document.createElement('link');
    style.rel = 'stylesheet',
        style.href = href;
    style.media = media;
    if (id)
        style.id = id;
    document.getElementsByTagName('head')[0].appendChild(style);
}
exports.loadStyle = loadStyle;
/**
 * Load a JavaScript file
 *
 * The callback stack is used to collect all callback functions
 * and call it at the same time to avoid callbacks too early.
 * Example: Creating three PoiMap Objects on the same page. Every Object tries to load Leaflet
 * but because of the used ID Leaflet is included only once (multiple Leaflet scripts would not make sense).
 * But every PoiMap needs to know when Leaflet is ready, therefore all callbacks are collected and called after
 * the load event of the script.
 *
 * @param src Path to script
 * @param id (Optional) ID for the script to prevent multiple scripts.
 * @param onLoadCallback (Optional) Required "id" parameter. Callack when script is loaded.
 */
function loadScript(src, id, onLoadCallback, type) {
    if (!window.OebbScripts) {
        /**
         * Script loading global object with "OebbScripts" namespace.
         */
        window.OebbScripts = {
            /**
             * Script loading callback Stack.
             * Object with "id" and "callback".
             */
            scriptCallbackStack: [],
            /**
             * Check if callback stack has been executed.
             * If the script is already on the page and a class need it later
             * the callback should be executed therefore we need to know if the
             * callbacks from the stack have been executed.
             */
            executedCallbacksById: [],
        };
    }
    var oebbScripts = window.OebbScripts;
    if (id && document.querySelector("#".concat(id))) {
        if (onLoadCallback && oebbScripts.executedCallbacksById.indexOf(id) == -1) {
            oebbScripts.scriptCallbackStack.push({
                id: id,
                callback: onLoadCallback
            });
        }
        else if (onLoadCallback) {
            if (oebbScripts.executedCallbacksById.indexOf(id) == -1)
                oebbScripts.executedCallbacksById.push(id);
            onLoadCallback();
        }
        return;
    }
    var script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.defer = true;
    if (id)
        script.id = id;
    if (type && type == 'module')
        script.type = 'module';
    if (type && type == 'nomodule')
        script.noModule = true;
    if (id && onLoadCallback) {
        oebbScripts.scriptCallbackStack.push({
            id: id,
            callback: onLoadCallback
        });
    }
    script.addEventListener('load', function () {
        if (oebbScripts.scriptCallbackStack.length) {
            for (var _i = 0, _a = oebbScripts.scriptCallbackStack; _i < _a.length; _i++) {
                var callbackObject = _a[_i];
                if (callbackObject.id == this.id) {
                    callbackObject.callback();
                }
            }
            if (oebbScripts.executedCallbacksById.indexOf(id) == -1)
                oebbScripts.executedCallbacksById.push(id);
        }
        else {
            if (onLoadCallback)
                onLoadCallback();
        }
    });
    document.body.appendChild(script);
}
exports.loadScript = loadScript;
function isIE() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');
    if (msie > 0 || /Trident.*rv[ :]*11\./.test(navigator.userAgent)) {
        return true;
    }
    return false;
}
exports.isIE = isIE;
function lazyLoadImages() {
    var images = document.querySelectorAll('.js-lazy-image');
    // Fallback for loading all images
    var fallback = function (images) {
        for (var i = 0; i < images.length; i++) {
            var image = images[i];
            image.src = image.dataset.src;
            image.srcset = image.dataset.srcset;
        }
    };
    // Loading if printing
    var isPrinting = false;
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (!isSafari) {
        window.onbeforeprint = function () {
            isPrinting = true;
            fallback(images);
        };
    }
    else {
        window.matchMedia('print').addListener(function () {
            isPrinting = true;
            fallback(images);
        });
    }
    if (isPrinting)
        return;
    // Lazy Loading
    if ('IntersectionObserver' in window
        && 'IntersectionObserverEntry' in window
        && 'intersectionRatio' in window.IntersectionObserverEntry.prototype
        && 'isIntersecting' in window.IntersectionObserverEntry.prototype) {
        var intersectionObserver_1 = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    var image = entry.target;
                    intersectionObserver_1.unobserve(image);
                    image.src = image.dataset.src;
                    image.srcset = image.dataset.srcset;
                    image.classList.remove('.js-lazy-image');
                }
            });
        }, { rootMargin: '300px' });
        for (var i = 0; i < images.length; i++) {
            intersectionObserver_1.observe(images[i]);
        }
    }
    else {
        // Fallback, just load all images
        fallback(images);
    }
}
exports.lazyLoadImages = lazyLoadImages;
function retrieveCurrentLanguage() {
    var currentLang = document.querySelector('html').getAttribute('lang');
    if (!currentLang)
        currentLang = 'de';
    return currentLang;
}
exports.retrieveCurrentLanguage = retrieveCurrentLanguage;
